import { Controller } from "@hotwired/stimulus"
import BuildingsSearchController, { Building } from "./buildings_search_controller"

type Marker = {
  closePopup: () => void
}

type Popup = {
  setHTML: (html: string) => Popup
}

declare class Rosen {
  constructor(_element: HTMLElement, _options: {
    apiKey: string
    zoom: number
    tileSetting: string
    apiSetting: string
    uiLanguage: string
  })
  clearStationPopups(): void
  setStationPopup(_stationCode: string, _popup: Popup, _isAutoOpen: boolean): Promise<Marker>
  static htmlPopup(_options: { closeButton: boolean }): Popup
}

export default class extends Controller {
  static values = {
    language: String,
    apiKey: String
  }
  static targets = [
    'map',
    'result'
  ]
  static outlets = [ "buildings-search" ]

  declare readonly mapTarget: HTMLElement
  declare readonly resultTarget: HTMLElement
  declare readonly buildingsSearchOutlet: BuildingsSearchController
  declare readonly languageValue: string
  declare readonly apiKeyValue: string
  declare rosen: Rosen

  connect() {
    if (!this.apiKeyValue) {
      return
    }

    // SEE: https://rmap.ekispert.jp/production/doc/document.html
    this.rosen = new Rosen(this.mapTarget, {
      apiKey: this.apiKeyValue,
      zoom: 16,
      tileSetting: this.languageValue === 'ja' ? 'https' : 'https_en',
      apiSetting: this.languageValue === 'ja' ? 'https' : 'https_en',
      uiLanguage: this.languageValue,
    })
    this.buildingsSearchOutlet.search()
  }

  clearMarkers() {
    this.rosen.clearStationPopups()
  }

  displayMarkers(buildings: Building[]) {
    buildings.forEach((building) => {
      const popupContent = `
        <div class="search__train-line-popup">
          <a href="${building.buildingUrl}">
            <img alt="${building.name}" src="${building.imageUrl}" loading="lazy">
            <div class="text">
              <h3>${building.name}</h3>
              <p>${building.keyPhrase}</p>
            </div>
          </a>
        </div>
      `

      const popup = Rosen.htmlPopup({
        closeButton: false
      }).setHTML(popupContent)
      this.rosen.setStationPopup(building.ekispertStationCode, popup, false)
        .then((marker: Marker) => {
          marker.closePopup()
        })
    })
  }

  onSearchCompleted({ detail: { buildings }}: { detail: { buildings: Building[] }}) {
    if (!this.apiKeyValue) {
      return
    }
    this.clearMarkers()
    this.displayMarkers(buildings)
  }
}
