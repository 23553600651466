import { Controller } from "@hotwired/stimulus"
import ModalVideo from 'modal-video'

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    new ModalVideo(this.modalTargets)
  }
}
