import { Controller } from "@hotwired/stimulus"
import BuildingsSearchController from "./buildings_search_controller"

export default class extends Controller {
  static targets = [
    'vacanciesCheckbox',
  ]
  static outlets = [ "buildings-search" ]

  declare readonly vacanciesCheckboxTarget: HTMLInputElement
  declare readonly buildingsSearchOutlet: BuildingsSearchController
  declare readonly hasBuildingsSearchOutlet: boolean

  update() {
    // NOTE: MAP検索と路線検索のときはAPIを実行する
    if (this.hasBuildingsSearchOutlet) {
      this.buildingsSearchOutlet.search()
    }
  }
}
