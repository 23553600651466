import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'station',
    'minutes',
    'count',
  ]

  declare readonly stationTarget: HTMLInputElement
  declare readonly minutesTarget: HTMLInputElement
  declare readonly countTarget: HTMLElement

  // NOTE: 検索結果の件数を「さらに条件を指定する」モーダル内にも反映するための処理
  countTargetConnected(element: HTMLElement) {
    const targetElements = document.querySelectorAll(".js-buildings-count")
    targetElements.forEach((targetElement) => {
      targetElement.textContent = element.textContent
    })
  }
}
