import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["detail"]

  declare readonly detailTargets: HTMLElement[]

  toggle(event: Event) {
    event.preventDefault()
    this.detailTargets.forEach(target => target.classList.toggle('show'))
  }
}
