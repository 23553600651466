import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(): void {
    this.resetHeights()
    this.matchHeightsByType()
    window.addEventListener("resize", this.onResize)
  }

  disconnect(): void {
    window.removeEventListener("resize", this.onResize)
  }

  private onResize = (): void => {
    this.resetHeights()
    this.matchHeightsByType()
  }

  private resetHeights(): void {
    this.element.querySelectorAll<HTMLElement>("[data-type]").forEach((element) => {
      element.style.height = "auto"
    })
  }

  private matchHeightsByType(): void {
    const groups: Record<string, HTMLElement[]> = {}

    this.element.querySelectorAll<HTMLElement>("[data-type]").forEach((element) => {
      const type = element.dataset.type
      if (!type) return
      if (!groups[type]) groups[type] = []
      groups[type].push(element)
    })

    // NOTE: 各グループ内で最大の高さを揃える
    Object.values(groups).forEach((elements) => {
      const maxHeight = Math.max(...elements.map((element) => element.offsetHeight))
      elements.forEach((element) => {
        element.style.height = `${maxHeight}px`
      })
    })
  }
}
