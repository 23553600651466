import { Controller } from "@hotwired/stimulus"
import { Loader } from '@googlemaps/js-api-loader'
import BuildingsSearchController, { Building } from "./buildings_search_controller"

const CAPITAL_CENTER = { lat: 35.696857285971824, lng: 139.62743447462867 }

export default class extends Controller {
  static values = {
    apiKey: String,
  }
  static targets = [
    'map',
    'tab',
    'result'
  ]
  static outlets = [ "buildings-search" ]

  declare readonly mapTarget: HTMLElement
  declare readonly tabTargets: HTMLElement[]
  declare readonly buildingsSearchOutlet: BuildingsSearchController
  declare readonly apiKeyValue: string
  declare loader: Loader
  declare map: google.maps.Map
  declare markers: google.maps.Marker[]

  // SEE: https://developers.google.com/maps/documentation/javascript?hl=ja
  initialize() {
    this.loader = new Loader({ apiKey: this.apiKeyValue, version: "3.58" })
    this.markers = []
  }

  connect() {
    if (!this.apiKeyValue) {
      return
    }
    this.loader.load()
      .then(async(google) => {
        this.map = new google.maps.Map(this.mapTarget, {
          center: CAPITAL_CENTER,
          zoom: 11,
          mapId: 'SEARCH_BUILDINGS_BY_MAP'
        })
        this.buildingsSearchOutlet.search()
      })
      .catch((e) => {
        console.error(e)
      })
  }

  changeCenterCapital(event: Event) {
    this.changeCenter(CAPITAL_CENTER)
    this.activateTab(event)
  }

  changeCenterKansai(event: Event) {
    this.changeCenter({ lat: 34.8964499059156, lng: 135.60918496152576 })
    this.activateTab(event)
  }

  changeCenter(newCenter: google.maps.LatLngLiteral) {
    this.map.setCenter(newCenter)
    this.map.setZoom(11)
  }

  onSearchCompleted({ detail: { buildings }} : { detail: { buildings: Building[] }}) {
    if (!this.apiKeyValue) {
      return
    }
    this.clearMarkers()
    this.displayMarkers(buildings)
  }

  clearMarkers() {
    this.markers.forEach((marker) => marker.setMap(null))
    this.markers = []
  }

  displayMarkers(buildings: Building[]) {
    buildings.forEach((building) => {
      const { name, latitude, longitude } = building

      if (latitude && longitude) {
        const marker = new google.maps.Marker({
          position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
          map: this.map
        })

        const infoWindow = new google.maps.InfoWindow({
          content: this.contentString(building),
          ariaLabel: name,
          headerDisabled: true
        })

        this.markers.push(marker)

        marker.addListener("click", () => {
          if (infoWindow.isOpen) {
            infoWindow.close()
          } else {
            infoWindow.open({
              anchor: marker,
              map: this.map,
            })
          }
        })

        this.map.addListener("click", () => {
          infoWindow.close()
        })
      }
    })
  }

  contentString(building: Building) {
    const { name, keyPhrase, imageUrl, buildingUrl } = building
    return `
      <div class="search__map-info-window">
        <a href=${buildingUrl}>
          <img class="search__map-image" alt=${name} src=${imageUrl} loading="lazy">
          <div class="search__map-text">
            <h3>${name}</h3>
            <p class="search__map-keyphrase">${keyPhrase}</p>
          </div>
        </a>
      </div>
  `
  }

  activateTab(event: Event) {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active")
    })

    const target = event.currentTarget as HTMLElement
    target.classList.add("active")
  }
}
