import { Controller } from "@hotwired/stimulus"

interface ContactFormEvent extends Event {
  params: {
    type: string;
  }
}

export default class extends Controller {
  static targets = ["text", "field"]

  declare readonly textTargets: HTMLElement[]
  declare readonly fieldTargets: HTMLElement[]
  formType: string = "none"

  connect() {
    const params = new URLSearchParams(window.location.search)
    const contactKindParams = params.get('contact_kind')
    if (contactKindParams) {
      this.formType = contactKindParams
    }
    this.updateForm()
  }

  switchForm(event: ContactFormEvent) {
    event.preventDefault()

    if (event.params) {
      this.formType = event.params.type
    }

    this.updateForm()
  }

  updateForm() {
    this.fieldTargets.forEach(target => {
      if (target.dataset.formTypes && target.dataset.formTypes.includes(this.formType)) {
        target.querySelectorAll('input, textarea, select').forEach(element => {
          (element as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement).disabled = false
        })
        target.classList.remove("is-hidden")
      } else {
        target.querySelectorAll('input, textarea, select').forEach(element => {
          (element as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement).disabled = true
        })
        target.classList.add("is-hidden")
      }
    })

    this.textTargets.forEach(target => {
      if (target.dataset.formTypes && target.dataset.formTypes.includes(this.formType)) {
        target.classList.remove("is-hidden")
      } else {
        target.classList.add("is-hidden")
      }
    })
  }

  validation(event: Event) {
    const nameInput = document.getElementById("name") as HTMLInputElement
    const emailInput = document.getElementById("mail") as HTMLInputElement
    if (nameInput && nameInput.value === "") {
      alert("お名前は必須項目です")
      event.preventDefault()
      return
    }
    if (emailInput && emailInput.value === "") {
      alert("メールアドレスは必須項目です")
      event.preventDefault()
      return
    }
  }
}
